import React, { useState } from "react";
import { Container, Button, Image, Modal } from "react-bootstrap";
import { Fade as Hamburger } from "hamburger-react";
import { NavLink, Link } from "react-router-dom";

import logo from "../../../assets/logo.svg";
import shippericon from "../../../assets/shippericon.svg";
import transportericon from "../../../assets/transportericon.svg";

// import { NavLink, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "../Navbar/navbar.css";


function StickyNavbar() {
  const [expanded, setExpanded] = useState(false);

  const [show, setShow] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Harmburgerstyle = {
    color: "#FFFFFF",
    outline: "none",
    border: 0,
  };

 

  return (
    <Navbar expand="lg"  className="sticky-navbar">
      <Container fluid>
        <Navbar.Brand>
          <NavLink to="/">
            <Image src={logo} />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle
          className="hamburger"
          aria-controls="basic-navbar-nav"
          style={Harmburgerstyle}
          onClick={() => setExpanded(!expanded)}
        >
          <Hamburger toggled={expanded} duration={0.8} hideOutline={false} />
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link >
              <NavLink to="/" style={{color:"#FFFFFF"}} activeStyle={{ color: "black" }}>
                Home
              </NavLink>
            </Nav.Link>
            <Nav.Link >
              <NavLink to="/aboutus" style={{color:"#FFFFFF"}} activeStyle={{ color: "black" }}>
                About us
              </NavLink>
            </Nav.Link>
            <Nav.Link >
              <NavLink to="/safety" style={{color:"#FFFFFF"}} activeStyle={{ color: "black" }} >
                Safety
              </NavLink>
            </Nav.Link>
            <Nav.Link >
              <NavLink  to="/whyloda" style={{color:"#FFFFFF"}} activeStyle={{ color: "black" }} >
                Why Loda
              </NavLink>
            </Nav.Link>
          </Nav>
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link >
              <NavLink to="/blog" style={{color:"#FFFFFF"}} activeStyle={{ color: "black" }}>
                Blog
              </NavLink>
            </Nav.Link>
            <Nav.Link >
              <NavLink to="/career" style={{color:"#FFFFFF"}} activeStyle={{ color: "black" }}>
                Career
              </NavLink>
            </Nav.Link>
            <Nav.Link >
              <NavLink to="/Contactus" style={{color:"#FFFFFF"}} activeStyle={{ color: "black" }} >
                Contact
              </NavLink>
            </Nav.Link>
            {"   "}
            {/* <Button
              onClick={handleShow}
              variant="primary"
              style={{
                backgroundColor: "#313434",
                borderColor: "#313434",
                color: "#FFFFF",
                borderRadius: 36 + "px",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.4 + "rem",
                  marginRight: 0.4 + "rem",
                }}
              >
                Join The Waitlist
              </span>
            </Button>{" "} */}


            <Modal
              className="Modalbody"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton style={{ outline: 'none', boxShadow: 'none' }} >
                <Modal.Title>
                  <strong style={{fontFamily:'jost',fontSize:1.2+'rem',color:'#F36F2E'}}>Select Waitlist</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
            
              <Link to="/shipperwaitlist">
              <div className="selectbox">
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                 <div style={{display:"flex",gap:5,textAlign:"center",alignItems:"center"}}>
                  <Image src={shippericon} />
                  <span style={{fontFamily:"jost",fontSize:0.9+"rem",color:"#000000",fontWeight:400,}}>Shipper/Rider</span>
                 </div>
                 <svg width="11" height="21" viewBox="0 0 31 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M2.56055 2.5L27.4399 30.4052L2.56055 61" stroke="#817F7F" stroke-width="5"/>
                 </svg>
                </div>
              </div>
              </Link>
              <br/>
              <Link to="/transporterwaitlist">
              <div className="selectbox">
              <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                <div style={{display:"flex",gap:5,textAlign:"center",alignItems:"center"}}>
                  <Image src={transportericon} />
                  <span style={{fontFamily:"jost",fontSize:0.9+"rem",color:"#000000",fontWeight:400,}}>Transporter</span>
                 
                </div>
                <svg width="11" height="21" viewBox="0 0 31 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M2.56055 2.5L27.4399 30.4052L2.56055 61" stroke="#817F7F" stroke-width="5"/>
                 </svg>
                </div>
              </div>
              </Link>
              <br/>
            
                
              </Modal.Body>
            </Modal>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default StickyNavbar;
