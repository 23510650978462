import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import StateDropdown from "../StateDropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Request from "../../../services/contactUs";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function BusinessForm() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    state: "",
    company_name: "",
    platform: "Loda",
    type: "waiting_list",
    waitlist_type: "shipper",
    account_type: "individual",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleOnchange = (setState) => (value, name) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // return console.log('payload', form)
      const res = await Request.createRequest("contacts", form);
      if (res) {
        if (res.status && res.status === true) {
          toast.success("Thanks for joining our waiting list");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          toast.error(res);
        }
      } else {
        console.log("res", res);
        toast.error("No response received from the server");
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Form onSubmit={handleSubmit} style={{ marginBottom: 1 + "rem" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="formlabel">Full Name</Form.Label>
          <Form.Control
            required
            // onChange={handleOnchange(setForm)}
            onChange={(e) => handleOnchange(setForm)(e.target.value, "name")}
            className="formcontrol"
            type="text"
            name="name"
            placeholder="Enter your full name"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="formlabel">Company Name</Form.Label>
          <Form.Control
            required
            // onChange={handleOnchange(setForm)}
            onChange={(e) => handleOnchange(setForm)(e.target.value, "company_name")}
            className="formcontrol"
            type="text"
            name="company_name"
            placeholder="Enter your full name"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="formlabel">Email</Form.Label>
          <Form.Control
            required
            // onChange={handleOnchange(setForm)}
            onChange={(e) => handleOnchange(setForm)(e.target.value, "email")}
            className="formcontrol"
            type="email"
            name="email"
            placeholder="Enter Email"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="tel">
          <Form.Label className="formlabel">Phone Number</Form.Label>
          <PhoneInput
            style={{ width: 100 + "%" }}
            country={"ng"}
            required
            value={form.phone}
            onChange={(value) => handleOnchange(setForm)(value, "phone")}
            className="formcontrol"
            type="tel"
            name="phone"
            placeholder="Enter phone number"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="state">
          <Form.Label className="formlabel">State</Form.Label>
          {/* <StateDropdown onChange={handleOnchange(setForm)} /> */}
          <StateDropdown
            onChange={(e) => handleOnchange(setForm)(e.target.value, "state")}
          />
        </Form.Group>

        <br />

        <Button
          type="submit"
          variant="primary"
          style={{
            backgroundColor: "#F36F2E",
            borderColor: "#F14810",
            borderRadius: 26 + "px",
          }}
          className="buttonform"
          size="md"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Loading...
              </span>
            </>
          ) : (
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
              }}
            >
              Join Now
            </span>
          )}
        </Button>
      </Form>
    </div>
  );
}

export default BusinessForm;
