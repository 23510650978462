import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Form, Button, Spinner } from "react-bootstrap";
import { ExternalLink } from "react-external-link";
import { Link } from "react-router-dom";
import logox1 from "../../assets/logox1.svg";
import logofoot from "../../assets/logofoot.svg";

import { useNavigate } from "react-router-dom";

import "../contactus/contactus.css";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Services from "../../services/contactUs";
import FormFooter from "../Formfooter/FormFooter";



function ContactUs() {

  const email = "Info@theloda.co";
  const phoneNumber = "+2348095916777";

  const navigate = useNavigate()

  const [form, setForm] = useState(
    { name: "", email: "", subject: "", message: "", platform: "Loda", type: "contact" }
  )
  const [isLoading, setIsLoading] = useState(false);



  const handleOnchange = (setState) => (event) => {
    const { value, name } = event.target
    setState((previous) => ({
      ...previous,
      [`${name}`]: value
    }))

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await Services.createRequest("contacts", form);
      if (res) {
        if (res.status && res.status === true) {
          toast.success("Thanks for contacting us, please await our response");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          toast.error(res);
        }
      } else {

        toast.error("No response received from the server");
      }
    } catch (error) {

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);

  return (

    <Container fluid className="contactus">

      <Row id="hero">



        <Col xs={12} md={6} lg={6} className="order-md-2 formmain">
          <Link to="/">
            <Image className="navlogodisplay" src={logofoot} fluid alt="image" />
          </Link>

          <h5 style={{ fontFamily: "jost", fontSize: 2.2 + "rem" }}>
            <strong>Get in touch!</strong>
          </h5>
          <p
            style={{
              fontFamily: "jost",
              fontSize: 0.8 + "rem",
              color: "#3C3C3C",
            }}
          >
            Contact us through any of our available channels or by filling the
            form below.
          </p>

          <ToastContainer />
          <Form onSubmit={handleSubmit} >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="formlabel">Fullname</Form.Label>
              <Form.Control
                required
                onChange={handleOnchange(setForm)}
                className="formcontrol"
                type="text"
                name="name"
                placeholder="Enter your full name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="formlabel">Email</Form.Label>
              <Form.Control
                required
                onChange={handleOnchange(setForm)}
                className="formcontrol"
                type="email"
                name="email"
                placeholder="Enter your email"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="formlabel">Subject</Form.Label>
              <Form.Control
                required
                onChange={handleOnchange(setForm)}
                className="formcontrol"
                type="text"
                name="subject"
                placeholder="Enter subject"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="formlabel">Message</Form.Label>
              <Form.Control
                required
                onChange={handleOnchange(setForm)}
                as="textarea"
                className="formcontrol"
                name="message"
                placeholder="Enter your message here"
                aria-label="With textarea"
              />
            </Form.Group>
            <br />
            <Button
              type="submit"
              variant="primary"
              style={{
                backgroundColor: "#F36F2E",
                borderColor: "#F14810",
                borderRadius: 26 + "px",
              }}
              className="buttonform"
              size="md"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.3 + "rem",
                      marginRight: 0.3 + "rem",
                    }}
                  >
                    Loading...
                  </span>
                </>
              ) : (
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.3 + "rem",
                    marginRight: 0.3 + "rem",
                  }}
                >
                  SEND MESSAGE
                </span>
              )}
            </Button>
          </Form>
        </Col>
        <Col xs={12} md={6} lg={6} className="order-md-1 formside">
          <Link to="/">
            <Image className="navlogo logoss" src={logox1} fluid alt="image" />
          </Link>
          <div
            className="otherContent"
            style={{
              marginTop: 50 + "vh",
              display: "flex",
              flexDirection: "column",
              gap: 1 + "rem",
            }}
          >
            <div style={{ display: "flex", gap: 0.7 + "rem" }}>
              <svg
                width="45"
                height="45"
                viewBox="0 0 61 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.8"
                  d="M30.5 61C47.3447 61 61 47.3447 61 30.5C61 13.6553 47.3447 0 30.5 0C13.6553 0 0 13.6553 0 30.5C0 47.3447 13.6553 61 30.5 61Z"
                  fill="#4C4743"
                />
                <path
                  d="M30.7614 29.6721C29.6636 29.6721 28.6107 29.236 27.8345 28.4598C27.0582 27.6835 26.6221 26.6307 26.6221 25.5329C26.6221 24.435 27.0582 23.3822 27.8345 22.6059C28.6107 21.8297 29.6636 21.3936 30.7614 21.3936C31.8592 21.3936 32.912 21.8297 33.6883 22.6059C34.4646 23.3822 34.9007 24.435 34.9007 25.5329C34.9007 26.0764 34.7936 26.6147 34.5856 27.1169C34.3776 27.6191 34.0727 28.0754 33.6883 28.4598C33.3039 28.8441 32.8476 29.149 32.3454 29.3571C31.8432 29.5651 31.305 29.6721 30.7614 29.6721ZM30.7614 13.9429C27.6875 13.9429 24.7396 15.1639 22.566 17.3375C20.3925 19.511 19.1714 22.459 19.1714 25.5329C19.1714 34.2254 30.7614 47.0571 30.7614 47.0571C30.7614 47.0571 42.3514 34.2254 42.3514 25.5329C42.3514 22.459 41.1303 19.511 38.9568 17.3375C36.7832 15.1639 33.8352 13.9429 30.7614 13.9429Z"
                  fill="#FFFFFF"
                />
              </svg>

              <span style={{
                color: "#C5C5C5",
                fontFamily: "jost",
                fontSize: 0.9 + "rem",
                width: 50 + "%",
              }}>
                No.44 Kudirat abiola way beside total filling station Oregun road Olusosun Bus-stop Ikeja, Lagos
              </span>

            </div>

            {/* <div style={{ display: "flex", gap: 0.7 + "rem" }}>
              <svg
                width="45"
                height="45"
                viewBox="0 0 61 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.8"
                  d="M30.5 61C47.3447 61 61 47.3447 61 30.5C61 13.6553 47.3447 0 30.5 0C13.6553 0 0 13.6553 0 30.5C0 47.3447 13.6553 61 30.5 61Z"
                  fill="#4C4743"
                />
                <path
                  d="M30.7614 29.6721C29.6636 29.6721 28.6107 29.236 27.8345 28.4598C27.0582 27.6835 26.6221 26.6307 26.6221 25.5329C26.6221 24.435 27.0582 23.3822 27.8345 22.6059C28.6107 21.8297 29.6636 21.3936 30.7614 21.3936C31.8592 21.3936 32.912 21.8297 33.6883 22.6059C34.4646 23.3822 34.9007 24.435 34.9007 25.5329C34.9007 26.0764 34.7936 26.6147 34.5856 27.1169C34.3776 27.6191 34.0727 28.0754 33.6883 28.4598C33.3039 28.8441 32.8476 29.149 32.3454 29.3571C31.8432 29.5651 31.305 29.6721 30.7614 29.6721ZM30.7614 13.9429C27.6875 13.9429 24.7396 15.1639 22.566 17.3375C20.3925 19.511 19.1714 22.459 19.1714 25.5329C19.1714 34.2254 30.7614 47.0571 30.7614 47.0571C30.7614 47.0571 42.3514 34.2254 42.3514 25.5329C42.3514 22.459 41.1303 19.511 38.9568 17.3375C36.7832 15.1639 33.8352 13.9429 30.7614 13.9429Z"
                  fill="#FFFFFF"
                />
              </svg>

              <span
                style={{
                  color: "#C5C5C5",
                  fontFamily: "jost",
                  fontSize: 0.9 + "rem",
                  width: 50 + "%",
                }}
              >
                205 Barde Road, Bompai Kano State,<br /> Nigeria
              </span>

            </div> */}

            <ExternalLink
              style={{ textDecoration: "none" }}
              href={`mailto:${email}`}
            >
              <div style={{ display: "flex", gap: 0.7 + "rem" }}>
                <svg
                  width="45"
                  height="45"
                  viewBox="0 0 61 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.8"
                    d="M30.5 61C47.3447 61 61 47.3447 61 30.5C61 13.6553 47.3447 0 30.5 0C13.6553 0 0 13.6553 0 30.5C0 47.3447 13.6553 61 30.5 61Z"
                    fill="#4C4743"
                  />
                  <path
                    d="M41.875 19H18.875C17.2937 19 16.0144 20.2937 16.0144 21.875L16 39.125C16 40.7062 17.2937 42 18.875 42H41.875C43.4562 42 44.75 40.7062 44.75 39.125V21.875C44.75 20.2937 43.4562 19 41.875 19ZM41.3 25.1094L31.1369 31.4631C30.6769 31.7506 30.0731 31.7506 29.6131 31.4631L19.45 25.1094C19.3059 25.0285 19.1796 24.9191 19.079 24.788C18.9783 24.6569 18.9053 24.5067 18.8643 24.3466C18.8234 24.1864 18.8153 24.0197 18.8407 23.8563C18.8661 23.693 18.9244 23.5365 19.012 23.3963C19.0996 23.2561 19.2147 23.1352 19.3504 23.0408C19.4861 22.9464 19.6396 22.8805 19.8015 22.8472C19.9634 22.8138 20.1303 22.8136 20.2923 22.8467C20.4543 22.8797 20.6079 22.9453 20.7438 23.0394L30.375 29.0625L40.0063 23.0394C40.1421 22.9453 40.2957 22.8797 40.4577 22.8467C40.6197 22.8136 40.7866 22.8138 40.9485 22.8472C41.1104 22.8805 41.2639 22.9464 41.3996 23.0408C41.5353 23.1352 41.6504 23.2561 41.738 23.3963C41.8256 23.5365 41.8839 23.693 41.9093 23.8563C41.9347 24.0197 41.9266 24.1864 41.8857 24.3466C41.8447 24.5067 41.7717 24.6569 41.671 24.788C41.5704 24.9191 41.4441 25.0285 41.3 25.1094Z"
                    fill="#FFFFFF"
                  />
                </svg>

                <span
                  style={{
                    color: "#C5C5C5",
                    fontFamily: "jost",
                    fontSize: 0.9 + "rem",
                    width: 75 + "%",
                    marginTop: 0.6 + "rem",
                  }}
                >
                  Info@theloda.co
                </span>
              </div>
            </ExternalLink>

            <ExternalLink
              style={{ textDecoration: "none" }}
              href={`tel:${phoneNumber}`}
            >
              <div style={{ display: "flex", gap: 0.7 + "rem" }}>
                <svg
                  width="45"
                  height="45"
                  viewBox="0 0 61 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.8"
                    d="M30.5 61C47.3447 61 61 47.3447 61 30.5C61 13.6553 47.3447 0 30.5 0C13.6553 0 0 13.6553 0 30.5C0 47.3447 13.6553 61 30.5 61Z"
                    fill="#4C4743"
                  />
                  <path
                    d="M37.2183 16.1421C37.2183 15.8392 37.3387 15.5487 37.5528 15.3345C37.767 15.1203 38.0575 15 38.3604 15C38.6633 15 38.9538 15.1203 39.168 15.3345C39.3822 15.5487 39.5025 15.8392 39.5025 16.1421V21.8524H45.2129C45.5158 21.8524 45.8062 21.9728 46.0204 22.1869C46.2346 22.4011 46.3549 22.6916 46.3549 22.9945C46.3549 23.2974 46.2346 23.5879 46.0204 23.8021C45.8062 24.0163 45.5158 24.1366 45.2129 24.1366H39.5025V29.847C39.5025 30.1499 39.3822 30.4403 39.168 30.6545C38.9538 30.8687 38.6633 30.989 38.3604 30.989C38.0575 30.989 37.767 30.8687 37.5528 30.6545C37.3387 30.4403 37.2183 30.1499 37.2183 29.847V24.1366H31.508C31.2051 24.1366 30.9146 24.0163 30.7004 23.8021C30.4862 23.5879 30.3659 23.2974 30.3659 22.9945C30.3659 22.6916 30.4862 22.4011 30.7004 22.1869C30.9146 21.9728 31.2051 21.8524 31.508 21.8524H37.2183V16.1421ZM22.9561 28.7574L25.407 26.5098C26.0778 25.8941 26.5493 25.0919 26.7609 24.2063C26.9725 23.3207 26.9147 22.392 26.5948 21.5395L25.5509 18.746C25.1601 17.7031 24.3863 16.8484 23.3873 16.3561C22.3883 15.8639 21.2392 15.771 20.174 16.0964C16.2544 17.2956 13.2416 20.9388 14.169 25.265C14.9519 28.8609 16.2979 32.3106 18.1571 35.4865C20.3728 39.2942 22.9036 42.1105 25.0735 44.0863C28.349 47.0694 33.0315 46.3225 36.0443 43.5153C36.8525 42.7622 37.343 41.7291 37.4156 40.6268C37.4882 39.5244 37.1374 38.4359 36.4349 37.5834L34.5162 35.2535C33.9372 34.5494 33.1609 34.0346 32.2869 33.7753C31.413 33.516 30.4814 33.5241 29.6121 33.7985L26.4417 34.7967C25.6229 33.9526 24.9054 33.0159 24.3038 32.0055C23.7228 30.9823 23.2702 29.8914 22.9561 28.7574Z"
                    fill="#FFFFFF"
                  />
                </svg>

                <span
                  style={{
                    color: "#C5C5C5",
                    fontFamily: "jost",
                    fontSize: 0.9 + "rem",
                    width: 75 + "%",
                    marginTop: 0.6 + "rem",
                  }}
                >
                  +2348095916777
                </span>
              </div>
            </ExternalLink>
          </div>
          <FormFooter />
        </Col>


      </Row>



    </Container>
  );
}

export default ContactUs;
