import React from "react";


import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import IndividulaForm from "./ShipperForms/IndividulaForm";
import BusinessForm from "./ShipperForms/BusinessForm";






function ShipperForm() {



  return (
    <Tabs
      defaultActiveKey="individual"
      id="justify-tab-example"
      className="mb-3 selectusertype-a"
      justify
    >
      <Tab style={{ marginTop: 0.3 + "rem" }} eventKey="individual" title="Individual">

        <IndividulaForm />

      </Tab>

      <Tab style={{ marginTop: 0.3 + "rem" }} eventKey="business" title="Business">

        <BusinessForm />

      </Tab>

    </Tabs>



  );
}

export default ShipperForm;
