import React, { Fragment, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import StickyNavbar from "../reuse-component/Navbar/StickyNavbar";
import FooterSection from "../homepage/component/FooterSection";
import "./PrivacyPolicy.css";
import { Link } from "react-router-dom";

function PrivacyPage() {
  // State variables for each list item
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  // Event handlers for each list item
  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  // Styles for the list items
  const listStyle = {
    paddingTop: 0.5 + "rem",
    paddingBottom: 0.5 + "rem",
    paddingLeft: 0.4 + "rem",
    borderRadius: 0 + "rem",
    fontSize: 0.89 + "rem",
    cursor: "pointer",
    borderBottomStyle: "solid",
    borderBottomWidth:0.1+'rem',
    borderBottomColor: '#BBBABA'
  };

  return (
    <Fragment>
      <StickyNavbar />
      <Container fluid id="hero" className="herosection">
        <Container fluid className="containersectionabt">
          <Row>
            <Col xs={12} md={8} lg={6} style={{ marginTop: -2 + "rem" }}>
              <h2
                style={{
                  color: "#FFFFFF",
                  fontFamily: "jost",
                  fontSize: 2.5 + "rem"
                }}
              >
                <strong>Privacy & Terms</strong>
              </h2>
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: 0.75 + "rem",
                  lineHeight: 224 + "%",
                  fontFamily: "jost"
                }}
              >
                For information on how to request deletion of your account and
                data associated with
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container style={{ borderWidth: 0.1 + "rem", borderColor: "#A4A4A4", borderStyle: "solid", padding: 1.3 + "rem", marginTop: -4 + "rem", width: 85 + "%", borderRadius: 0.4 + "rem" }}>
        <Link style={{color:"black"}} to="/privacypolicy">
        <Row
          style={{ ...listStyle, backgroundColor: isHovered1 ? "#EEEEEE" : "white" }}
          onMouseEnter={handleMouseEnter1}
          onMouseLeave={handleMouseLeave1}
        >
          Privacy Policy
        </Row>
        </Link> 
       
        <Link style={{color:"black"}} to="/termscondition">
        <Row
          style={{ ...listStyle, backgroundColor: isHovered2 ? "#EEEEEE" : "white" }}
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
        >
          Terms & Conditions
        </Row>
        </Link>

        <Link style={{color:"black"}} to="/data-deletion">
        <Row
          style={{ ...listStyle, backgroundColor: isHovered3 ? "#EEEEEE" : "white" ,borderBottomWidth:0+'rem',}}
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
        >
          Data Deletion
        </Row>
        </Link>
      </Container>

      <FooterSection />
    </Fragment>
  );
}

export default PrivacyPage;
